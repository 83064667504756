.MoldalMolMain
    display: table
    background: rgba(0,0,0,.75)
    width: 100%
    height: 100%
    overflow: hidden
    position: fixed
    top: 0
    left: 0
    z-index: 10000

    .MoldalMolWrap
        +bx
        +center(vh)
        // padding: $spacer32
        width: 100%
        min-width: 280px
        height: auto
        background-color: white
        background: transparent
        // position: relative
        &.MoldalMolWrapXs
            max-width: 320px
        &.MoldalMolWrapSmall2
            max-width: 400px
        &.MoldalMolWrapSmall
            max-width: 480px
        
        &.MoldalMolWrapMedium3
            max-width: 620px
        &.MoldalMolWrapMedium2
            max-width: 740px
        &.MoldalMolWrapMedium
            max-width: 960px
        &.MoldalMolWrapBig
            max-width: 1200px
        &.MoldalMolWrapFullWidth
            max-width: 100%

        .MoldalMolClose
            position: absolute
            top: $spacer
            right: $spacer
            cursor: pointer
            +font-size(18px)
        // .MoldalMolScrollbar