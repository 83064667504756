@import "../../abstracts/variables"
@import "../../abstracts/mixins"

/*MLocation*/
.mLocation
    .mLocationMain
        .mLocationTitle
            +font-size(24px)
            font-weight: 700
            text-align: center
            margin-bottom: $spacer2 + 6
        .mLocationSelect
            + .mLocationSelect
                margin-top: $spacer + 4
            label
                margin-bottom: $spacer0
                display: block
        .mLocationActions
            +flex
            +flexDir(column)
            +justifyCont(center)
            margin: 0 auto
            margin-top: $spacer2
            max-width: 100%
            text-align: center
            +mediaMin640
                max-width: 220px
            button

            a
                margin-top: $spacer2 + 4