//- Mixines
@import "../abstracts/mixins"

// Color Icons
[class^="cIco-"], [class*=" cIco-"]
	&:before
		content: ''
		width: 60px
		height: 60px
		display: block
		background-position: center center
		background-repeat: no-repeat
		background-size: contain

// .cIco-4g:before
// 	background-image: url(../../../Assets/vector/ico-4g.svg)
// .cIco-24-horas:before
// 	background-image: url(../../../Assets/vector/ico-24-horas.svg)
// .cIco-agendar-cita:before
// 	background-image: url(../../../Assets/vector/ico-agendar-cita.svg)
// .cIco-agregar-servicio-movil:before
// 	background-image: url(../../../Assets/vector/ico-agregar-servicio-movil.svg)
// .cIco-altas-velocidades:before
// 	background-image: url(../../../Assets/vector/ico-altas-velocidades.svg)
// .cIco-bloqueo-sim-card:before
// 	background-image: url(../../../Assets/vector/ico-bloqueo-sim-card.svg)
// .cIco-celulares-con-plan:before
// 	background-image: url(../../../Assets/vector/ico-celulares-con-plan.svg)
// .cIco-celulares-prepago:before
// 	background-image: url(../../../Assets/vector/ico-celulares-prepago.svg)
// .cIco-chat-en-linea:before
// 	background-image: url(../../../Assets/vector/ico-chat-en-linea.svg)
// .cIco-chip:before
// 	background-image: url(../../../Assets/vector/ico-chip.svg)
// .cIco-cobertura:before
// 	background-image: url(../../../Assets/vector/ico-cobertura.svg)
// .cIco-conexion-total:before
// 	background-image: url(../../../Assets/vector/ico-conexion-total.svg)
// .cIco-consumo:before
// 	background-image: url(../../../Assets/vector/ico-consumo.svg)
// .cIco-contactanos:before
// 	background-image: url(../../../Assets/vector/ico-contactanos.svg)
// .cIco-control-parental:before
// 	background-image: url(../../../Assets/vector/ico-control-parental.svg)
// .cIco-crear-cuenta:before
// 	background-image: url(../../../Assets/vector/ico-crear-cuenta.svg)
// .cIco-decodificador:before
// 	background-image: url(../../../Assets/vector/ico-decodificador.svg)
// .cIco-factura:before
// 	background-image: url(../../../Assets/vector/ico-factura.svg)
// .cIco-fallas-del-servicio:before
// 	background-image: url(../../../Assets/vector/ico-fallas-del-servicio.svg)
// .cIco-login:before
// 	background-image: url(../../../Assets/vector/ico-login.svg)
// .cIco-menu-telefonico:before
// 	background-image: url(../../../Assets/vector/ico-menu-telefonico.svg)
// .cIco-mi-claro:before
// 	background-image: url(../../../Assets/vector/ico-mi-claro.svg)
// .cIco-movil-user:before
// 	background-image: url(../../../Assets/vector/ico-movil-user.svg)
// .cIco-nuevo-equipo:before
// 	background-image: url(../../../Assets/vector/ico-nuevo-equipo.svg)
// .cIco-nuevo-servicio:before
// 	background-image: url(../../../Assets/vector/ico-nuevo-servicio.svg)
// .cIco-pagar-factura:before
// 	background-image: url(../../../Assets/vector/ico-pagar-factura.svg)
// .cIco-pago-personalizado:before
// 	background-image: url(../../../Assets/vector/ico-pago-personalizado.svg)
// .cIco-portabilidad:before
// 	background-image: url(../../../Assets/vector/ico-portabilidad.svg)
// .cIco-recarga:before
// 	background-image: url(../../../Assets/vector/ico-recarga.svg)
// .cIco-reversa-portabilidad:before
// 	background-image: url(../../../Assets/vector/ico-reversa-portabilidad.svg)
// .cIco-saldos-y-consumos:before
// 	background-image: url(../../../Assets/vector/ico-saldos-y-consumos.svg)
// .cIco-saldos:before
// 	background-image: url(../../../Assets/vector/ico-saldos.svg)
// .cIco-sap:before
// 	background-image: url(../../../Assets/vector/ico-sap.svg)
// .cIco-servicio-movil:before
// 	background-image: url(../../../Assets/vector/ico-servicio-movil.svg)
// .cIco-servicios-hogar:before
// 	background-image: url(../../../Assets/vector/ico-servicios-hogar.svg)
// .cIco-sim:before
// 	background-image: url(../../../Assets/vector/ico-sim.svg)
// .cIco-tecnologia-y-accesorios:before
// 	background-image: url(../../../Assets/vector/ico-tecnologia-y-accesorios.svg)
// .cIco-tv:before
// 	background-image: url(../../../Assets/vector/ico-tv.svg)
// .cIco-web:before
// 	background-image: url(../../../Assets/vector/ico-web.svg)
	
// .cIco-equipo-user:before
// 	background-image: url(../../../Assets/vector/ico-equipo-user.svg)
// .cIco-adquirir-servicio:before
// 	background-image: url(../../../Assets/vector/ico-adquirir-servicio.svg)

// .cIco-adquirir-servicio2:before
// 	background-image: url(../../../Assets/vector/ico-adquirir-servicio2.svg)
// .cIco-servicios-tienda:before
// 	background-image: url(../../../Assets/vector/ico-servicios-tienda.svg)
// .cIco-comunicacion-confirmacion:before
// 	background-image: url(../../../Assets/vector/ico-comunicacion-confirmacion.svg)
// .cIco-correo-confirmacion:before
// 	background-image: url(../../../Assets/vector/ico-correo-confirmacion.svg)

// .cIco-factura2:before
// 	background-image: url(../../../Assets/vector/ico-factura2.svg)
// .cIco-saldos2:before
// 	background-image: url(../../../Assets/vector/ico-saldos2.svg)
// .cIco-agricultura:before
// 	background-image: url(../../../Assets/vector/ico-agricultura.svg)
// .cIco-salud:before
// 	background-image: url(../../../Assets/vector/ico-salud.svg)
// .cIco-gobierno:before
// 	background-image: url(../../../Assets/vector/ico-gobierno.svg)
// .cIco-finanzas:before
// 	background-image: url(../../../Assets/vector/ico-finanzas.svg)

// .cIco-casa-inteligente:before
// 	background-image: url(../../../Assets/vector/ico-casa-inteligente.svg)
// .cIco-computadoras:before
// 	background-image: url(../../../Assets/vector/ico-computadoras.svg)
// .cIco-smartphones:before
// 	background-image: url(../../../Assets/vector/ico-smartphone.svg)
// .cIco-smart-tv:before
// 	background-image: url(../../../Assets/vector/ico-smart-tv.svg)
// .cIco-videojuegos:before
// 	background-image: url(../../../Assets/vector/ico-videojuegos.svg)
// .cIco-vigilancia:before
// 	background-image: url(../../../Assets/vector/ico-vigilancia.svg)


// .cIco-24-horas2:before
// 	background-image: url(../../../Assets/vector/ico-24-horas2.svg)
// .cIco-cambiazo:before
// 	background-image: url(../../../Assets/vector/ico-cambiazo.svg)
// .cIco-envia-solicitudes:before
// 	background-image: url(../../../Assets/vector/ico-envia-solicitudes.svg)
// .cIco-compra-paquetes:before
// 	background-image: url(../../../Assets/vector/ico-compra-paquetes.svg)
// .cIco-accede-servicios:before
// 	background-image: url(../../../Assets/vector/ico-accede-servicios.svg)
// .cIco-realiza-pagos:before
// 	background-image: url(../../../Assets/vector/ico-realiza-pagos.svg)
// .cIco-redireccionar:before
// 	background-image: url(../../../Assets/vector/ico-redireccionar.svg)
// .cIco-check-hexagono:before
// 	background-image: url(../../../Assets/vector/ico-check-hexagono.svg)

// .cIco-miClaro:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/ico-miClaro.svg)

// .cIco-miClaro-empresas:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/ico-miClaro-empresas.svg)

// .cIco-miClaro-corporaciones:before
// 	background-image: url(../../../Assets/vector/ico-miClaro-corporaciones.svg)

// .cIco-claro-musica:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-claro-musica.svg)
// .cIco-claro-video:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-claro-video.svg)

// .cIco-tnt-sports:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-tnt-sports.svg)
// .cIco-netflix:before
// 	width: 112px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-netflix.svg)
// .cIco-spotify:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-spotify.svg)

// .cIco-hbo:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-hbo.svg)
// .cIco-star:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-star.svg)
// .cIco-paquete-total:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-paquete-total.svg)
// .cIco-golden:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-golden.svg)
// .cIco-deezer:before
// 	width: 120px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-deezer.svg)
// .cIco-zoom:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-zoom.svg)
// .cIco-google-meet:before
// 	width: 120px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-google-meet.svg)
// .cIco-google-maps:before
// 	width: 120px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-google-maps.svg)
// .cIco-wikipedia:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-wikipedia.svg)
// .cIco-khan-academy:before
// 	width: 120px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-khan-academy.svg)
// .cIco-uber:before
// 	width: 100px
// 	height: 30px
// 	background-image: url(../../../Assets/vector/logo-uber.svg)