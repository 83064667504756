@import "../../abstracts/variables"
@import "../../abstracts/mixins"
	
// Modal Modalidad Compra
.modalModCompra
	h2, h3
		text-align: center
		margin-bottom: $spacer3
	.modalModCompraOptions
		width: 100%
		max-width: 320px
		margin: 0 auto
		.radio
			font-weight: bold
			+ .radio
				margin-top: $spacer2
	.mmcDesc
		+font-size(12px)
		text-align: center
		margin-top: $spacer2
	[class*="btn"]
		display: table
		width: 100%
		max-width: 220px
		margin: 0 auto
		margin-top: $spacer2
	.modalModCompraExtra
		+bx
		+borderRadius(12px)
		width: calc(100% + #{$spacer3 * 2})
		margin-left: -$spacer3
		margin-right: -$spacer3
		margin-top: $spacer3
		padding: $spacer2 $spacer2 0
		background-color: $gris1
		.mmcTop
			+mediaMin640
				+flex
				+alignItem(flex-end)
				+justifyCont(space-between)
			p
				+font-size(14px)
				&.mmcTopLeft
					span
						+font-size(12px)
						display: inline-block
						width: 100%
						margin-bottom: $spacer0
						+mediaMin640
							margin-bottom: 0
				&.mmcTopRight
					font-weight: bold
					+mediaMin640
						padding-left: $spacer
					strong
						color: $rojo6
				+ p
					margin-top: $spacer0
			+ .mmcBottom
				margin-top: $spacer0
				padding-bottom: $spacer0
		.mmcTopExtra
			+font-size(12px)
			position: relative
			margin: $spacer 0
			padding-left: $spacer2
			// &:before
			// 	+borderRadius(50%)
			// 	content: ''
			// 	position: absolute
			// 	width: 3px
			// 	height: 3px
			// 	left: 8px
			// 	top: 6px
			// 	display: block
			// 	background-color: $color-text
		.mmcBottom
			padding-bottom: $spacer
			+mediaMin640
				+flex
				+justifyCont(space-between)
			.mmcBottomCol
				.mmcSocialNet
					+bx
					+borderRadius($spacer/2)
					min-width: 170px
					padding: $spacer/2 $spacer
					background-color: #fff
					span
						+font-size(10px)
					ul
						+flex
						margin-top: 2px
						li
							+font-size(12px)
							color: #525252
							+ li
								margin-left: $spacer/2
						&.apps
							margin-top: 5px
							li
								i
									&:before
										height: 14px
									&.cIco-uber
										&:before
											width: 31px
									&.cIco-deezer
										&:before
											width: 51px
									&.cIco-spotify
										&:before
											width: 35px
									&.cIco-tnt-sports
										&:before
											width: 35px
									&.cIco-claro-video
										&:before
											width: 55px
								+ li
									margin-left: $spacer0
				.mmcList
					ul
						li
							+font-size(12px)
							position: relative
							padding-left: $spacer2
							+ li
								margin-top: $spacer0
							&:before
								+borderRadius(50%)
								content: ''
								position: absolute
								width: 3px
								height: 3px
								left: 8px
								top: 6px
								display: block
								background-color: $color-text
				+ .mmcBottomCol
					margin-top: $spacer
					+mediaMin640
						margin-top: 0