@import "../../abstracts/variables"
@import "../../abstracts/mixins"

/*MServiceHome*/

.mServiceHome
    width: 740px
    max-width: 100% 
    padding: $spacer2
    +mediaMin640
        padding: $spacer3
    +mediaMin960
        max-width: auto
    .mServiceHomeMain
        .mServiceHomeCont

            .mServiceHomeHeader
                position: relative
                .mServiceHomePlan
                    +bx
                    position: absolute
                    background: $gris9
                    // background: $amarillo9
                    // top: 0
                    top: -$spacer3
                    padding: ($spacer + 2) ($spacer2)
                    border-radius: 0 0 $spacer2 $spacer2
                    width: fit-content
                    +mediaMin640
                        padding: $spacer2
                    p
                        color: #fff
                        font-weight: 700
                        +font-size(24px)
                        line-height: 24px
                        letter-spacing: 0
                        +mediaMin640
                            font-weight: 500
                            +font-size(24px)
                            line-height: 24px
                .mServiceHomeInfo
                    padding-top: calc( #{$spacer3 / 2} + #{$spacer2} + 4px)
                    +mediaMin640
                        padding-top: calc( #{$spacer3} + #{$spacer2} + 4px)
                    .mServiceHomeSpeed
                        p
                            font-weight: 500
                            color: $gris9
                            // color: $amarillo9
                            +font-size(24px)
                            line-height: 32px
                            letter-spacing: 0
                            +mediaMin640
                                +font-size(32px)
                                line-height: 32px
                    .mServiceHomeBuild
                        padding-top: $spacer - 2
                        p
                            font-weight: 400
                            line-height: 16px
                            
            .mServiceHomeBody
                padding-top: $spacer2 + 4
                .mServiceHomeFeatures
                    +bx
                    +flex
                    +flexWrap
                    margin: -$spacer (0)
                    +mediaMin640
                        +flex
                        +flexWrap
                        width: calc(100% + 20px)
                        // margin-left: -$spacer2
                        // margin-right: -$spacer2
                        // margin-top: -$spacer2
                        margin: -$spacer2 (-$spacer0)
                    .mServiceHomeFeature
                        +bx
                        +flex
                        +flexDir
                        width: 50%
                        margin: $spacer 0
                        +mediaMin640
                            width: calc(50% - #{$spacer3 / 2})
                            margin: $spacer2 $spacer0
                        +mediaMin960
                            width: calc(33.33% - #{$spacer3 / 2})
                        + .mServiceHomeFeature
                            padding-left: $spacer
                            +mediaMin640
                                padding-left: 0
                        .mServiceHomeFeatureTitle
                            +flex
                            +alignItem(center)
                            margin-top: -$spacer2
                            padding-top: #{$spacer3 / 2}
                            i
                                +font-size(18px)
                                + p
                                    padding-left: $spacer0
                            p
                                font-weight: 700
                                +font-size(12px)
                                line-height: 12px
                        .mServiceHomeFeatureItem
                            +flex
                            margin-top: $spacer - 2
                            i
                                // padding-right: $spacer - 2
                                &::before
                                    +font-size(15px)
                                    +mediaMin640
                                        +font-size(18px)
                                + .mServiceHomeFeatureItemTitle
                                    padding-left: $spacer - 2
                                + .mServiceHomeFeatureItemVal
                                    padding-left: $spacer - 2
                            .mServiceHomeFeatureItemTitle
                                // padding-right: $spacer - 2
                                p
                                    +font-size(14px)
                                    line-height: 18px
                                    letter-spacing: 0
                                    +mediaMin640
                                        +font-size(14px)
                                        line-height: 18px
                                + .mServiceHomeFeatureItemVal
                                    padding-left: $spacer - 2
                            .mServiceHomeFeatureItemRS
                                padding-right: $spacer
                                ul
                                    +flex
                                    +flexWrap
                                    margin: -#{$spacer / 2}
                                    li
                                        min-width: 24px
                                        margin: $spacer / 2
                                        // a
                                        //     +flex(!important)
                                        //     +justifyCont
                                        //     +alignItem
                                        //     +font-size(18px)
                                        //     display: inline-block
                                        //     width: 24px
                                        //     height: 24px
                                        //     background-color: $gris8
                                        //     border-radius: 50px
                                        //     // position: relative
                                        //     i
                                        //         +font-size(14px)
                                        //         color: #fff
                                        //         padding-right: 0
                                        a
                                            display: inline-block
                                            i
                                                display: inline-block
                                                &:before
                                                    +font-size(24px)
                            .mServiceHomeFeatureItemVal
                                +flex
                                +alignItem(center)
                                p
                                    font-weight: 700
                                    +font-size(14px)
                                    line-height: 16px
                                    min-width: 60px
                                    +mediaMin640
                                        line-height: 14px
                .mServiceHomeAmount
                    +flex
                    +justifyCont(space-between)
                    +flexDir(column)
                    padding-top: $spacer2 + 4px
                    +mediaMin640
                        padding-top: $spacer3
                        +justifyCont(flex-end)
                        +flexDir(row)
                    .mServiceHomeProm
                        +bx
                        background: $gris1
                        padding: $spacer3 / 2
                        border-radius: $spacer
                        width: 100%
                        +mediaMin640
                            width: 340px
                            max-width: 340px
                        p
                            +font-size(12px)
                            line-height: 12px
                            font-weight: 500
                        .mServiceHomePromGal
                            +flex   
                            +flexWrap
                            padding-top: $spacer - 2
                            picture

                                img
                                    object-fit: contain
                                + picture
                                    padding-left: $spacer3
                    .mServiceHomeAmountCont
                        +flex
                        +justifyCont(flex-end)
                        padding-top: $spacer2
                        +mediaMin640
                            padding-top: 0
                            width: calc(100% - 340px)
                        // &.mServiceHomeAmountCont2
                        //     .mServiceHomePrices
                        //         .symbol
                        //             p
                        //                 color: $rojo6
                        //         .mServiceHomePrice
                        //             p
                        //                 color: $rojo6
                                
                        //         .decimal
                        //             p
                        //                 color: $rojo6
                        .mServiceHomeAmountText
                            +bx
                            +flex
                            +alignItem
                            padding-right: $spacer
                            p
                                font-weight: 700

                        .mServiceHomePrices
                            +flex
                            +flexWrap(nowrap)
                            +justifyCont(flex-end)
                            // width: 50%
                            place-self: center
                            .symbol
                                align-self: center
                                p
                                    font-weight: 600
                                    line-height: 21px
                                    color: $gris7
                            .mServiceHomePrice
                                align-self: baseline
                                p
                                    font-weight: 600
                                    +font-size(40px)
                                    line-height: 40px
                                    letter-spacing: 0
                                    color: $gris7
                                    +mediaMin640
                                        +font-size(40px)
                                        line-height: 40px
                            
                            .decimal
                                +flex
                                +alignItem(flex-start)
                                
                                p
                                    font-weight: 600
                                    line-height: 21px
                                    color: $gris7
        .mServiceHomeFoot
            +bx
            margin-top: $spacer3
            margin-right: -$spacer3
            // margin-right: -$spacer5
            margin-left: -$spacer3
            // margin-left: -$spacer5
            margin-bottom: -$spacer3
            // margin-bottom: -$spacer5
            background: $gris1
            padding: $spacer $spacer3 $spacer3
            .mServiceHomeListDet

                list-style: inside
                li
                    // +flex
                    // padding-top: $spacer2
                    list-style: inside
                    &::marker
                        +font-size(24px)
                        line-height: 32px
                        color: $gris9
                        // color: $amarillo9
                   
                    span
                        position: relative
                        left: -15px
                        +font-size(14px)
                        line-height: 18px
                        letter-spacing: 0
                        +mediaMin640
                            +font-size(14px)
                            line-height: 18px
    &.azul
        .mServiceHomeMain
            .mServiceHomeCont

                .mServiceHomeHeader
                    .mServiceHomePlan
                        background: $azul5
                    .mServiceHomeInfo
                        .mServiceHomeSpeed
                            p
                                color: $azul5

        .mServiceHomeFoot
            .mServiceHomeListDet
                li
                    &::marker
                        color: $azul5
    &.amarillo
        .mServiceHomeMain
            .mServiceHomeCont

                .mServiceHomeHeader
                    .mServiceHomePlan
                        background: $amarillo9
                    .mServiceHomeInfo
                        .mServiceHomeSpeed
                            p
                                color: $amarillo9

        .mServiceHomeFoot
            .mServiceHomeListDet
                li
                    &::marker
                        color: $amarillo9
    &.rojo
        .mServiceHomeMain
            .mServiceHomeCont

                .mServiceHomeHeader
                    .mServiceHomePlan
                        background: $rojo6
                    .mServiceHomeInfo
                        .mServiceHomeSpeed
                            p
                                color: $rojo6

        .mServiceHomeFoot
            .mServiceHomeListDet
                li
                    &::marker
                        color: $rojo6
    &.gris
        .mServiceHomeMain
            .mServiceHomeCont

                .mServiceHomeHeader
                    .mServiceHomePlan
                        background: $gris4
                    .mServiceHomeInfo
                        .mServiceHomeSpeed
                            p
                                color: $gris4

        .mServiceHomeFoot
            .mServiceHomeListDet
                li
                    &::marker
                        color: $gris4